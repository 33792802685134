<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="container">
      <div class="row mt-5">
        <div class="col text-center">
          <h2 class="display-4">{{ $t("menu.payment") }}</h2>
          <div v-if="serviceRequest">
            <h2 class="text-center display-5 text-uppercase mb-2">
              Richiesta per
              {{ serviceRequest.assisted_name }}
            </h2>
            <!-- @todo: Service Request Card -->
            <h4>Totale: &euro; {{ serviceRequest.prezzo }}</h4>
          </div>
            <div v-if="!servicePaid" id="paypal-buttons"></div>
          <div class="my-2 text-center">
            <b-button @click="goToList" type="submit" variant="success"
              >{{ $t("buttons.back_to_service_requests") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import i18n from "@/i18n";
import { loadScript } from "@paypal/paypal-js";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
import { processStatuses } from "@/services/endpoints";

export default {
  name: "Payment",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.payment"),
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    serviceRequest: function (newValue, _oldValue) {
      if (newValue && newValue.process_status === processStatuses.confirmed) {
        this.initPayPal();
      } else {
        this.goToList();
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: "serviceRequests/loadingServiceRequested",
      serviceRequest: "serviceRequests/serviceRequested",
    }),
    servicePaid() {
      return (
        this.serviceRequest &&
        this.serviceRequest.process_status === processStatuses.paid
      );
    },
  },
  methods: {
    ...mapActions({
      clearError: "alert/clear",
      showError: "alert/error",
      showSuccess: "alert/success",
      loadServiceRequest: "serviceRequests/getServiceRequestById",
      updateServiceRequest: "updateServiceRequest/updateServiceRequest",
      updateServicePaidRequest: "serviceRequests/updateServicePaidRequest",
      sendInvoice: "serviceRequests/sendInvoice",
    }),
    goToList() {
      router.push({
        name: "service_requests",
      });
      console.log("page payment " + this.id);

    },
    async initPayPal() {
      const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID || null;
      if (!clientId || null === this.id) {
        alert("PaypPal Client ID is not configured.");
        // Cart or PayPal request ID not found
        await router.push({ name: "home" });
        return;
      }
      let paypal;

      try {
        paypal = await loadScript({ "client-id": clientId, currency: "EUR" });
      } catch (error) {
        console.error("failed to load the PayPal JS SDK script", error);
      }

      if (paypal) {
        try {
          await paypal
            .Buttons({
              createOrder: this.createOrder,
              onApprove: this.onApprove,
              onCancel: this.onCancel,
              onError: this.onError,
            })
            .render("#paypal-buttons");
        } catch (error) {
          console.error("failed to render the PayPal Buttons", error);
        }
      }
    },
    createOrder(data, actions) {
      console.log("createOrder", data, actions);
      this.clearError();
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: this.serviceRequest?.prezzo,
            },
          },
        ],
      });
    },
    async onApprove(data, actions) {
      // data: orderID, payerID, paymentID, paymentSource
      console.log("onApprove", data, actions);
      const response = await actions.order.capture();
      // data: id, status, create_time, intent, payer, purchase_units
      console.log("capture response", response);
      this.serviceRequest.process_status = processStatuses.paid;
      await this.updateServicePaidRequest(this.serviceRequest);
      this.showSuccess("Pagamento effettuato con successo");
      await this.sendInvoice(this.serviceRequest);
      return response;
    },
    onCancel(data, actions) {
      console.log("onCancel", data, actions);
      this.showError("Pagamento annullato");
    },
    onError(data, actions) {
      console.log("onError", data, actions);
      this.showError("Si è verificato un errore in fase di pagamento");
    },
  },
  async mounted() {
    this.clearError();
    if (!this.id) {
      this.goToList();
    } else {
      this.loadServiceRequest(this.id);
    }
  },
};
</script>
