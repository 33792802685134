var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row mt-5" }, [
        _c("div", { staticClass: "col text-center" }, [
          _c("h2", { staticClass: "display-4" }, [
            _vm._v(_vm._s(_vm.$t("menu.payment")))
          ]),
          _vm.serviceRequest
            ? _c("div", [
                _c(
                  "h2",
                  { staticClass: "text-center display-5 text-uppercase mb-2" },
                  [
                    _vm._v(
                      " Richiesta per " +
                        _vm._s(_vm.serviceRequest.assisted_name) +
                        " "
                    )
                  ]
                ),
                _c("h4", [
                  _vm._v("Totale: € " + _vm._s(_vm.serviceRequest.prezzo))
                ])
              ])
            : _vm._e(),
          !_vm.servicePaid
            ? _c("div", { attrs: { id: "paypal-buttons" } })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "my-2 text-center" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "submit", variant: "success" },
                  on: { click: _vm.goToList }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("buttons.back_to_service_requests")) + " "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }